import Vue from "vue";
import TitleMixin from "@/mixins/title";
import SpinnerMixin from "@/mixins/spinner";
import { mapGetters } from "vuex";
export default Vue.extend({
    mixins: [TitleMixin, SpinnerMixin],
    data() {
        return {
            title: this.$t("equipments.edit.title"),
            id: this.$route.params.id,
            equipment: {},
            offices: [],
            validationOptions: {
                rules: {
                    officeId: {
                        required: true
                    },
                    name: {
                        required: true,
                        rangeLength: [3, 50]
                    },
                    quantity: {
                        required: true,
                        digits: true,
                        min: 0
                    }
                }
            }
        };
    },
    computed: {
        ...mapGetters({
            hasMultipleOffices: "account/hasMultipleOffices"
        })
    },
    methods: {
        edit() {
            return this.$store.dispatch("equipment/edit", {
                id: this.id,
                equipment: this.equipment
            });
        },
        editSuccess() {
            this.$toast.showSuccessToast(this.$t("equipments.edit.toastSuccessTitle"), this.$t("equipments.edit.toastSuccessContent", {
                name: this.equipment.name
            }));
            return this.$router.push("/administration/equipments");
        },
        cancel() {
            return this.$router.push("/administration/equipments");
        }
    },
    async mounted() {
        this.showSpinner();
        try {
            this.offices = await this.$store.dispatch("office/getAll");
            this.equipment = await this.$store.dispatch("equipment/get", {
                id: this.id
            });
            this.title += ` ${this.equipment.name}`;
            this.hideSpinner();
        }
        catch (errors) {
            this.hideSpinner();
            this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("shared.toastFailureContent", {
                error: errors[0].message
            }));
            await this.$router.push("/administration/equipments");
        }
    }
});
